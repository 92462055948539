<template>
  <div class="equipment-wrap ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>设备管理</div>
    <div class="ly-center">
      <div class="over-view">
        <div class="view-item">
          <h5>网关数</h5>
          <p>{{ stateData.gatewayNum }}</p>
        </div>
        <div class="view-item">
          <h5>从机数</h5>
          <p>{{ stateData.deviceNum }}</p>
        </div>
        <div class="view-item">
          <h5>变量数</h5>
          <p>{{ stateData.variableNum }}</p>
        </div>
      </div>
      <div class="gateway-box">
        <div class="gateway-item" v-for="item in stateData.stateList" :key="item.id">
          <div class="gateway-title">
            <div class="bg-icon"></div>
            <p>{{ item.gatewayName }}({{ item.sin }})</p></div>
          <div class="gateway-icon">
            <div class="icon-item">
              <h5>状态</h5>
              <div class="info">
                <span v-if="item.state==0" class="line on"></span>
                <span v-else class="line off"></span>
              </div>
            </div>
            <div class="icon-item">
              <h5>网络</h5>
              <div class="info" v-if="item.state==1"><span>- -</span></div>
              <div class="info" v-else>
                <span v-if="item.networkType==0" style="color: red;">故障</span>
                <span v-if="item.networkType==1" style="color: green;">初始化</span>
                <span v-if="item.networkType==2" style="color: #36ABF3;">2G</span>
                <span v-if="item.networkType==3" style="color: #36ABF3;">3G</span>
                <span v-if="item.networkType==4" style="color: #36ABF3;">4G</span>
                <span v-if="item.networkType==5" style="color: #36ABF3;">有线</span>
                <span v-if="item.networkType==6" style="color: #36ABF3;">WIFI</span>
              </div>
            </div>
            <div class="icon-item">
              <h5>信号</h5>
              <div class="info" v-if="item.state==1 || item.networkType==0 || item.networkType==5 "><span>- -</span>
              </div>
              <div class="info" v-else>
                <span v-if="item.networkSignal<5" class="net low"></span>
                <span v-if="item.networkSignal>5 && item.networkSignal<12" class="net normal"></span>
                <span v-if="item.networkSignal>11" class="net good"></span>
              </div>
            </div>
          </div>
          <div class="gateway-number">
            <div class="number-item">
              <h5>从机数</h5>
              <p>{{ item.deviceNum }}</p>
            </div>
            <div class="number-item">
              <h5>变量数</h5>
              <p>{{ item.variableNum }}</p>
            </div>
            <div class="number-item">
              <h5>刷新频率</h5>
              <input type="text" class="number-input" v-model="item.refreshNum" disabled><span>s</span>
              <!--              <input type="text" class="number-input" v-model="item.refreshNum"-->
              <!--                     @change="handleChange(item)"><span>s</span>-->
            </div>
          </div>
          <div class="gateway-tips" v-show="item.state==1"> *{{ item.remarks }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {getSelectWater,} from "../utils/auth";
import {getAction} from '../api/manage'
import {MessageBox, Toast} from "mint-ui";

export default {
  components: {},
  computed: {},
  data() {
    return {
      stateData: {
        stateList: []
      }
    }
  },
  mounted() {

  },
  created() {
    this.loadBase()
  },
  methods: {
    async loadBase() {
      const selectWater = await getSelectWater()
      const body = {
        waterId: selectWater.id,
      }
      getAction('/dac/gatewayState/queryStateByWaterId', body).then(res => {
        this.stateData = res.data.result
      })
    },
    handleChange() {
      // 是否更改刷新频率
      MessageBox.confirm('是否更改刷新频率？').then(action => {
        Toast('操作成功！');
      }).catch((err) => {
        console.log(err)
      });
    },
    goBack() {
      this.$router.go(-1)
    },
  },
  destroyed: function () {
  },
}
</script>

<style lang="less" scoped>
h5 {
  padding: 0;
  margin: 0;
}

.over-view {
  width: 100%;
  height: 1.7rem;
  background: #ffffff;
  display: flex;
  border-radius: 0.32rem;
  box-shadow: 0 10px 20px 0 rgba(0, 64, 128, 0.04);

  .view-item {
    flex: 1;
    height: 1.7rem;
    text-align: center;

    h5 {
      font-size: 0.28rem;
      color: #1E1F20;
      letter-spacing: 0;
      margin: 0;
      line-height: 0.7rem;
      padding-top: 0.2rem;
    }

    p {
      font-size: 0.4rem;
      color: #36ABF3;
      letter-spacing: 0;
      line-height: 0.4rem;
    }
  }
}

.gateway-box {
  width: 100%;
  height: auto;
  margin-top: 0.3rem;

  .gateway-item {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 0.32rem;
    box-shadow: 0 10px 20px 0 rgba(0, 64, 128, 0.04);
    margin-bottom: 0.2rem;

    .gateway-title {
      width: 100%;
      height: 1rem;
      border-bottom: 2px solid #F6F6F6;

      .bg-icon {
        width: 0.64rem;
        height: 0.64rem;
        float: left;
        background-image: url("../assets/img/equipment/gateway.png");
        background-repeat: no-repeat;
        background-position: center;
        background-color: rgba(62, 157, 255, 0.16);
        background-size: 0.5rem;
        border-radius: 0.14rem;
        margin-top: 0.2rem;
        margin-left: 0.3rem;
        margin-right: 0.2rem;
      }


      p {
        display: inline-block;
        line-height: 1.1rem;

      }
    }

    .gateway-icon {
      display: flex;
      height: 1.3rem;
      overflow: hidden;
      text-align: center;
      padding-top: 0.24rem;

      .icon-item {
        flex: 1;

        .info {
          padding-top: 0.1rem;

          .line {
            width: 0.6rem;
            height: 0.6rem;
            display: inline-block;
          }

          .on {
            background: url("../assets/img/equipment/on-line.png") no-repeat center;
            background-size: contain;
          }

          .off {
            background: url("../assets/img/equipment/off-line.png") no-repeat center;
            background-size: contain;
          }

          .net {
            width: 0.6rem;
            height: 0.6rem;
            display: inline-block;
          }

          .low {
            background: url("../assets/img/equipment/low.png") no-repeat center;
            background-size: contain;
          }

          .normal {
            background: url("../assets/img/equipment/normal.png") no-repeat center;
            background-size: contain;
          }

          .good {
            background: url("../assets/img/equipment/good.png") no-repeat center;
            background-size: contain;
          }
        }
      }
    }

    .gateway-number {
      display: flex;
      height: 1.3rem;
      overflow: hidden;
      text-align: center;

      .number-item {
        flex: 1;
        line-height: 0.6rem;

        p {
          font-size: 0.4rem;
          color: #36ABF3;
          letter-spacing: 0;
        }

        span {
          color: #36ABF3;
          letter-spacing: 0;
        }

        .number-input {
          border: none;
          outline: none;
          background-color: rgba(62, 157, 255, 0.16);
          max-width: 0.8rem;
          height: 0.6rem;
          border-radius: 0.2rem;
          text-align: center;
          color: #36ABF3;
          font-size: 0.4rem;
        }
      }
    }

    .gateway-tips {
      padding: 0.2rem;
      color: #F5222D;
    }
  }

}

</style>
